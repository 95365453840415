import React from 'react';
import PackageCard from '../components/PackageCard';
import InquiryForm from '../components/InquiryForm';

const PackagesPage = () => {
  return (
    <div className="min-h-screen bg-gray-100 p-12">
      <header className="text-center mb-12">
        <h1 className="text-4xl font-extrabold mb-4">Travel Packages to Romania</h1>
        <p className="text-lg text-gray-700">Explore our exciting travel packages and find the perfect adventure tailored just for you.</p>
      </header>
      <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <PackageCard 
          title="Transylvania Adventure" 
          description="Explore the heart of Transylvania with visits to historic castles, scenic landscapes, and local cultural experiences." 
          days={7} 
          price={999} 
          image="Transylvania.jpg"
        />
      </section>
      <footer className="mt-12 max-w-4xl mx-auto bg-white p-8 shadow-xl rounded-lg">
        <h2 className="text-3xl font-semibold mb-6 text-center">Inquire About Packages</h2>
        <InquiryForm />
      </footer>
    </div>
  );
};

export default PackagesPage;
