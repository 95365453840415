import React from 'react';
import TransylvaniaImage from '../assets/Transylvania.jpg';

const HeroSection = () => {
  return (
    <div className="relative w-full h-screen bg-cover bg-center" style={{ backgroundImage: `url(${TransylvaniaImage})` }}>
      <div className="absolute inset-0 bg-black opacity-50"></div>
      
      <div className="relative flex flex-col items-center justify-center h-full text-white text-center p-4">
        <h1 className="text-5xl md:text-6xl font-bold mb-4">Discover the Magic of Romania</h1>
        <p className="text-lg md:text-xl mb-8">Explore stunning landscapes, rich history, and unique culture.</p>
        <a href="#explore" className="bg-yellow-500 text-black px-6 py-3 rounded-lg text-lg font-semibold hover:bg-yellow-600 transition duration-300">
          Explore Now
        </a>
      </div>
    </div>
  );
};

export default HeroSection;
