import React from 'react';
import HeroSection from '../components/HeroSection';
import Navigation from '../components/Navigation';
import Transylvania from '../assets/Transylvania.jpg';

const HomePage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-red-600 to-white text-gray-900">
      <Navigation />
      <HeroSection />
      
      <section className="p-12 max-w-4xl mx-auto bg-white shadow-xl rounded-lg mt-12 transition-transform transform hover:scale-105">
        <h2 className="text-5xl font-extrabold mb-6 text-center">Introduction</h2>
        <p className="text-lg text-gray-800 leading-relaxed">Discover Romania, a land of diverse landscapes, rich history, and vibrant culture. From the majestic Carpathian Mountains to the historic castles of Transylvania, Romania offers a unique blend of experiences that will captivate your senses and leave you with unforgettable memories.</p>
      </section>
      
      <section className="p-12 bg-gray-100 mt-12">
        <div className="max-w-5xl mx-auto">
          <h2 className="text-5xl font-extrabold mb-8 text-center">Highlights</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
            <div className="bg-white p-8 rounded-lg shadow-xl transition-transform transform hover:scale-105 hover:shadow-2xl">
              <img src={Transylvania} alt="Transylvania Castles" className="w-full h-60 object-cover mb-4 rounded-md"/>
              <h3 className="text-3xl font-semibold mb-3">Transylvania Castles</h3>
              <p className="text-gray-700">Explore the legendary castles of Transylvania, including Bran Castle and Peles Castle, each with its own intriguing history and stunning architecture.</p>
            </div>
            <div className="bg-white p-8 rounded-lg shadow-xl transition-transform transform hover:scale-105 hover:shadow-2xl">
              <img src={Transylvania} alt="Danube River" className="w-full h-60 object-cover mb-4 rounded-md"/>
              <h3 className="text-3xl font-semibold mb-3">Danube River</h3>
              <p className="text-gray-700">Cruise along the Danube River, taking in the breathtaking landscapes and charming villages that line its banks. A tranquil way to experience Romania's natural beauty.</p>
            </div>
            <div className="bg-white p-8 rounded-lg shadow-xl transition-transform transform hover:scale-105 hover:shadow-2xl">
              <img src={Transylvania} alt="Maramureș Traditions" className="w-full h-60 object-cover mb-4 rounded-md"/>
              <h3 className="text-3xl font-semibold mb-3">Maramureș Traditions</h3>
              <p className="text-gray-700">Experience the rich traditions of Maramureș, a region known for its well-preserved wooden churches, vibrant folk art, and hospitable communities.</p>
            </div>
            <div className="bg-white p-8 rounded-lg shadow-xl transition-transform transform hover:scale-105 hover:shadow-2xl">
              <img src={Transylvania} alt="Bucharest’s Architecture" className="w-full h-60 object-cover mb-4 rounded-md"/>
              <h3 className="text-3xl font-semibold mb-3">Bucharest’s Architecture</h3>
              <p className="text-gray-700">Discover the diverse architectural styles of Bucharest, from the grandiose Palace of the Parliament to the charming Old Town.</p>
            </div>
          </div>
        </div>
      </section>
      
      <section className="p-12 max-w-4xl mx-auto bg-white shadow-xl rounded-lg mt-12 transition-transform transform hover:scale-105">
        <h2 className="text-5xl font-extrabold mb-6 text-center">Why Visit Romania</h2>
        <p className="text-lg text-gray-800 mb-6">Romania offers a rich tapestry of experiences that cater to every traveler. From the picturesque landscapes and historical landmarks to the warm hospitality of its people, Romania promises a memorable journey for every visitor. Explore vibrant cities, tranquil countryside, and everything in between.</p>
        <ul className="list-disc pl-5 text-lg text-gray-800 space-y-3">
          <li>Rich cultural heritage with a blend of Eastern and Western influences.</li>
          <li>Stunning natural landscapes including mountains, rivers, and forests.</li>
          <li>Unique traditions and folklore, including the famous Dracula legend.</li>
        </ul>
      </section>
      
      <section className="p-12 bg-gray-100 mt-12">
        <div className="max-w-5xl mx-auto">
          <h2 className="text-5xl font-extrabold mb-8 text-center">Testimonials</h2>
          <div className="space-y-8">
            <blockquote className="bg-white p-8 rounded-lg shadow-xl transition-transform transform hover:scale-105 hover:shadow-2xl">
              <p className="text-lg text-gray-800 mb-4">"Our trip to Romania was nothing short of magical. From the beautiful landscapes to the rich history, every moment was an adventure. Highly recommend visiting Transylvania!"</p>
              <footer className="text-right">
                <cite className="text-gray-600 text-lg">- Alex Johnson</cite>
              </footer>
            </blockquote>
            <blockquote className="bg-white p-8 rounded-lg shadow-xl transition-transform transform hover:scale-105 hover:shadow-2xl">
              <p className="text-lg text-gray-800 mb-4">"Romania exceeded all our expectations. The local traditions, friendly people, and breathtaking views made for an unforgettable experience."</p>
              <footer className="text-right">
                <cite className="text-gray-600 text-lg">- Maria Gonzales</cite>
              </footer>
            </blockquote>
          </div>
        </div>
      </section>
      
      <footer className="p-12 bg-gray-900 text-white mt-12">
        <h2 className="text-5xl font-extrabold mb-6 text-center">Contact Us</h2>
        <form className="max-w-3xl mx-auto flex flex-col space-y-6">
          <input type="text" placeholder="Name" className="p-4 border border-gray-700 rounded bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-300" />
          <input type="email" placeholder="Email" className="p-4 border border-gray-700 rounded bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-300" />
          <textarea placeholder="Message" className="p-4 border border-gray-700 rounded bg-gray-800 text-white h-40 focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-300"></textarea>
          <button type="submit" className="bg-blue-600 text-white p-4 rounded hover:bg-blue-700 transition duration-300">Send</button>
        </form>
      </footer>
    </div>
  );
};

export default HomePage;
