import React from 'react';
import { Link } from 'react-router-dom'; 

const Navigation = () => {
  return (
    <nav className="bg-gradient-to-r from-blue-500 to-purple-600 p-4 shadow-lg">
      <div className="container mx-auto flex items-center justify-between">
        <div className="text-white text-3xl font-extrabold">
          <Link to="/" className="hover:text-yellow-300 transition-colors duration-300">ComeTO</Link>
        </div>
        <ul className="flex space-x-6">
          <li>
            <Link 
              to="/" 
              className="text-white hover:text-yellow-300 transition-colors duration-300 text-lg font-medium"
            >
              Home
            </Link>
          </li>
          <li>
            <Link 
              to="/packages" 
              className="text-white hover:text-yellow-300 transition-colors duration-300 text-lg font-medium"
            >
              Packages
            </Link>
          </li>
          <li>
            <a 
              href="#contact" 
              className="text-white hover:text-yellow-300 transition-colors duration-300 text-lg font-medium"
            >
              Contact
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navigation;
