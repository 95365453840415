import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Turnstile } from '@marsidev/react-turnstile';

function Widget() {
    return <Turnstile siteKey='0x4AAAAAAAixHVVxGOkMnB5J' />;
}

const InquiryForm = () => {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phoneNumber: '',
        preferredPackage: '',
        numberOfPeople: '',
        additionalRequests: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    };

    const handlePhoneChange = (value) => {
        setFormData({
            ...formData,
            phoneNumber: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.fullName || !formData.email || !formData.phoneNumber || !formData.preferredPackage || !formData.numberOfPeople) {
            alert('Please fill out all required fields.');
            return;
        }

        const response = await fetch('http://localhost:3000/send-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'your-api-token'
            },
            body: JSON.stringify({
                to: 'form.bookin@cometo.ro',
                subject: 'New Booking Inquiry',
                text: `
                    Full Name: ${formData.fullName}
                    Email: ${formData.email}
                    Phone Number: ${formData.phoneNumber}
                    Preferred Package: ${formData.preferredPackage}
                    Number of People: ${formData.numberOfPeople}
                    Additional Requests: ${formData.additionalRequests}
                `,
            })
        });

        if (response.ok) {
            alert('Email sent successfully!');
        } else {
            alert('Failed to send email.');
        }
    };

    return (
        <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
            <input 
                type="text" 
                name="fullName" 
                placeholder="Full Name" 
                value={formData.fullName}
                onChange={handleChange}
                className="p-2 border border-gray-400 rounded" 
                required
            />
            <input 
                type="email" 
                name="email" 
                placeholder="Email Address" 
                value={formData.email}
                onChange={handleChange}
                className="p-2 border border-gray-400 rounded" 
                required
            />

            <PhoneInput
                country={'ro'}
                value={formData.phoneNumber}
                onChange={handlePhoneChange}
                inputClass="p-2 border border-gray-400 rounded"
                containerClass="w-full"
                inputStyle={{ width: '100%' }}
                required
            />

            <select 
                name="preferredPackage" 
                value={formData.preferredPackage}
                onChange={handleChange}
                className="p-2 border border-gray-400 rounded"
                required
            >
                <option value="">Preferred Package</option>
            </select>
            <input 
                type="number" 
                name="numberOfPeople" 
                placeholder="Number of People" 
                value={formData.numberOfPeople}
                onChange={handleChange}
                className="p-2 border border-gray-400 rounded" 
                required
            />
            <textarea 
                name="additionalRequests" 
                placeholder="Additional Requests" 
                value={formData.additionalRequests}
                onChange={handleChange}
                className="p-2 border border-gray-400 rounded h-32"
            ></textarea>
            <Widget />
            <button type="submit" className="bg-blue-500 text-white p-2 rounded">Inquire Today</button>
        </form>
    );
};

export default InquiryForm;
