import React from 'react';
import PropTypes from 'prop-types';

const PackageCard = ({ title, description, days, price, image }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
      <img src={require(`../assets/${image}`)} alt={title} className="w-full h-48 object-cover" />
      <div className="p-6">
        <h3 className="text-2xl font-semibold mb-2">{title}</h3>
        <p className="text-gray-700 mb-4">{description}</p>
        <p className="text-gray-800 font-bold mb-4">{days} Days | ${price}</p>
        <a href="#inquire" className="bg-blue-500 text-white px-4 py-2 rounded-lg text-center block hover:bg-blue-600 transition duration-300">
          Inquire Now
        </a>
      </div>
    </div>
  );
};

PackageCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  days: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
};

export default PackageCard;
